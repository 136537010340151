.Welcome{
  width: auto;
  text-align: center;
  color: #ffffff;
}

.logo img{
  position: relative;
  margin: auto;
  width: 90%;
}

.TimeTillHome{
  background-color: rgba(20, 20, 20, 0.9);
  font-size: 1.4rem;
  margin:1rem auto;
  padding: 1rem;
  width: fit-content;
  text-transform: none;
  text-decoration: underline;
  color: hsl(205, 78%, 60%);
  cursor: pointer;
}

.Countdown_text{
  background-color: rgba(20, 20, 20, 0.9);
  font-size: 1.8rem;
  margin:auto;
  padding: 1rem;
  width: fit-content;
  text-transform: none;
}

@media screen and (min-width: 800px) {
  .logo img{
    max-width: 40%;
  }

  .Countdown_text{
    font-size: 3rem;
  }
}

@media screen and (max-width: 400px) {
  .Countdown_text{
    font-size: 1.5rem;
}
}
