@font-face {
  font-family: Betterlove;
  src:  url('/src/Fonts/Betterlove.ttf') format('truetype');
  src:  url('/src/Fonts/Betterlove.woff') format('woff');
  src:  url('/src/Fonts/Betterlove.woff2') format('woff2');
}
@font-face {
  font-family: Dancing Script;
  src: url('/src/Fonts/DancingScript.ttf') format('truetype');
  src: url('/src/Fonts/DancingScript.woff') format('woff');
  src: url('/src/Fonts/DancingScript.woff2') format('woff2');
}

@font-face {
  font-family: Courgette;
  src: url('/src/Fonts/Courgette-Regular.ttf') format('truetype');
  src: url('/src/Fonts/Courgette-Regular.woff') format('woff');
  src: url('/src/Fonts/Courgette-Regular.woff2') format('woff2');
}

body {
  margin: 0;
  top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./img/Uppsala.jpg');
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
}

button {
  padding: 1em;
  color: white;
  background-color: #008CBA;
  border: 2px solid white;
  border-radius: 1em;
}

h1, h2, h3, h4 {
  font-family: 'Courgette', cursive;
  letter-spacing: var(--spacing);
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}

hr{
  border: 2px solid hsl(205, 78%, 60%);
  border-radius: 69%;
  margin: 1rem auto;
}

a{
  color: hsl(205, 78%, 60%);
  font-weight: bold;
  text-decoration: hsl(205, 78%, 60%) underline;
}

a:hover{
  color: hsl(205, 78%, 60%);
}