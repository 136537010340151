#OverMij hr.small{
  min-width: 80%;
}

#Blogs .intro{
  padding: 1rem;
}

#Blogs .intro h4{
  color: hsl(209, 34%, 30%);
  margin-top: 0;
}

#Blogs .intro h3{
  margin-top: 0;
  font-size: 1.4rem;
}

#Blogs .intro p{
  padding: 0;
  display: inline-block;
}

.Page{
  max-width: 1000px;
  margin: auto;
  background-color: #F7F7F7;
  padding: 0;
  transition: margin-top 1s ease-in-out;
  overflow: hidden;
}

.Page h1{
  text-align: center;
  padding: 1rem 0.5rem;
  margin: 0;
}

.Page p{
  max-width: 70ch;
  padding: 0 1rem;
  margin: auto;
  color: black;
}

.Page .intro{
  max-width: 70ch;
  margin: auto;
}
.Page .intro .Link{
  display: inline-block;
}

.Page .intro h3{
  color: black; 
}

.Page .introBox{
  text-decoration: none; 
}

.Page .introBox p{
  font-weight: 500;
  font-style: italic;
}

hr.small{
  min-width: 80%;
}

.SlideDiv{
  margin: 2ch auto 0 !important;
}

.imgDiv{
  display: flex;
  justify-content: center;
  margin: 2ch auto;
  max-width: 60ch;
  flex-direction: column;
  padding: 1rem;
}
.imgDiv img{
  max-height: 25rem;
  object-fit: contain;
}
.imgDiv p.subtitle{
  font-style: italic;
  font-size: 0.8rem;
  max-width: 90%;
  text-align: center;
  padding: 0;
}

.imgDiv div{
  width: 100% !important;
}

#profilePic{
  max-width: 90%;
  max-height: 400px;
}


@media screen and (min-width: 800px) {
  .Page{
    margin: 2rem auto;
    padding: 1rem 1rem 2rem;
    transition: margin-top 1s ease-in-out;
  }

  .Page h1{
    margin: initial;
    padding: 1rem 1.5rem;
  }

  .Page p{
    padding: 0;
    text-align: justify;
  }

}