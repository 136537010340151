.Logo_B{
  display:inline-block;
  font-family: Dancing Script;
  font-weight: bold;
  width: auto;
  color: black;
  background-color: #F8F9FA;
  margin: 0;
}
  
.Logo_Rest {
  display:inline-block;
  font-family: Dancing Script;
  font-weight: bold;
  color: black;
  background-color: #F8F9FA;
  overflow: hidden;
  text-transform: none;
  width: 0px;
  margin: 0;
  transition: color cubic-bezier(0, 0, 1,-0.07) 3s,
              width ease-in-out 0.8s,
              padding cubic-bezier(1, 0.05, 1, 0.73) 0.8s;
  vertical-align: bottom;
  padding-left: 2px;
  width:5.2rem;
}

nav {
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background-color: #F8F9FA;
  border-bottom: 5px solid black;
  box-shadow: 2px 2px 5px black;
  overflow: hidden;
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 1rem;
}
.nav-toggle {
  font-size: 1.5rem;
  color: hsl(205, 78%, 60%);
  background: transparent;
  border-color: transparent;
  transition: all 0.3s linear;
  cursor: pointer;
  padding: 1em;
}
.nav-toggle:hover {
  color: hsl(205, 86%, 17%);;
  transform: rotate(90deg);
}
.nav_logo h1{
  font-size: 2.3rem;
}

ul.links {
  margin: 0;
  list-style-type:none;
}

.links a {
  color: hsl(209, 34%, 30%);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  display: block;
  padding: 0.5rem 1rem;
  transition: all 0.3s linear;
  text-decoration: none;
}
.links a:hover {
  background: hsl(205, 86%, 81%);
  color: hsl(205, 78%, 60%);
  padding-left: 1.5rem;
}
.links-container {
  overflow: hidden;
  transition: all 0.3s linear;
}
.show-container {
  height: 10rem;
}

.nav-header .LinksAndWeather{
  display: contents;
}

.LinksAndWeather{
  flex-wrap: nowrap;
  align-items: center;
}

.weather p, .weather_Phone{
  font-style: italic;
  color: hsl(205, 78%, 60%);
  font-size: 0.9rem;
  font-weight: bold;
}
.weather{
  padding-top: 5px;
  display: none;
}

.weatherIcon{
  height: 2rem;
  padding-left: 0.3rem;
}
.weather_Phone{
  display: flex;
  align-items: center;
}

a.NavUppsala{
  text-decoration: none;
}

@media screen and (min-width: 800px) {
  .NavUppsala{
    display: inline-block;
  }
  .weather p{
    font-size: 1.3rem;
    margin: 0 0.5em; 
  }
  .weather{
    margin: 0 1rem; 
    display: flex;
  }
  .LinksAndWeather{
    display: flex;
  }
  .Logo_Rest {
    color: white;
    overflow: hidden;
    width: 0px;
    margin: 0;
    transition-delay: 2s;
    transition: color cubic-bezier(0, 0, 1,-0.07) 3s,
                width ease-in-out 0.8s,
                padding cubic-bezier(1, 0.05, 1, 0.73) 0.8s;
    vertical-align: bottom;
  }

  .nav_logo:hover .Logo_Rest{
    width: 5.2rem;
    color: black;
    padding-left: 2px;
    transition: width ease-out 1s;
}
  nav{
    border-bottom: 3px solid black;
  }
  
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle, .nav-header .LinksAndWeather{
    display: none;
  }
  ul.links {
    padding: 0;
  }

  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
  .links a {
    padding: 0;
    margin: 0 0.5rem;
    font-size: 1.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
}

@media screen and (max-width: 400px) {
  .nav_logo h1{
    font-size: 1.5rem;
  }
  .Logo_Rest {
    width: 3.5rem;
  }
}
@media screen and (max-width: 300px) {
  .weatherIcon{
    display: none;
  }
}