#TTS_text{
    background-color: rgba(20, 20, 20, 0.9);
    font-size: 1.8rem;
    margin:auto;
    padding: 1rem;
    width: fit-content;
    text-transform: none;
}

.Countdown {
    color: white;
    background-color: rgba(20, 20, 20, 0.9);
    border: 2px 2px black;
    width: fit-content;
    margin: 0.5em auto;
    padding: 0.3em;
}

.Countdown{
    padding: 0.5rem;
}
.Countdown .Colon, .Countdown div{
    display: inline-flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: space-around;
    padding: 0.2rem;
}

.Countdown p{
    color: white;
    margin: 0;
}

.Countdown div p{
    text-align: center;
    display: block;
}

.Countdown .Colon{
    font-size: 3rem;
}

.Countdown div p.Text{
    font-size: 1rem;
}

.Countdown div p.Number{
    font-size: 3rem;
}

@media screen and (min-width: 800px) {
    .Countdown .Colon, .Countdown div{
        padding: 1rem;
    }
    #TTS_text{
        font-size: 3rem;
    }
}

@media screen and (max-width: 400px) {
    .Countdown .Colon, .Countdown div{
        padding: 0.1rem;
        font-size: 1.5rem;
    }

    .Countdown div p.Text{
        font-size: 0.9rem;
    }
    
    .Countdown div p.Number{
        font-size: 2rem;
    }
    #TTS_text{
        font-size: 1.5rem;
    }
}