.Blog{
  padding: 1rem;
  overflow: hidden;
}
.Blog ul, 
.Blog ol,
.Blog p{
  max-width: 70ch;
  padding: 0 0 1ch;
  margin: auto;
}

.Blog ul.Checklist{
  list-style: none;
}
.Blog ul.Checklist li{
  background-image: url(../../img/ul_Check.png);
  padding-left: 3rem;
  background-position: 0 0;
  background-size: 1.6rem 1.6rem;
  background-repeat: no-repeat;
  line-height: 1.5rem;
}

.Blog .Date{
  margin-top: 0;
  color: hsl(209, 34%, 30%);
}

.Blog h2{
  text-align: center;
  margin-top: 0;
}

.Blog h3{
  text-align: center;
}

.Blog p{
  padding-bottom: 1rem;
}

.Blog .slide-container{
  max-width: 70ch;
  margin: auto;
}

.VerticalCenter{
  width: inherit;
}

.prevNextButs{
  max-width: 70ch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

#prevBlog, #nextBlog {
  padding: 0.5em;
  border: 0.2rem;
  border-radius: 12px;
  border-style: outset;
  max-width: 14ch;
}

#prevBlog{
  text-align: right;
}
#nextBlog{
  text-align: left;
}

#prevBlog span, #nextBlog span{
  color:rgb(144, 144, 144);
  word-wrap: break-word;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-decoration: rgba(0, 0, 0, 0) underline;
  display:inline-block;
}

/* Blog 1 */
#Checklist{
  max-height: 2rem;
}

.Tooltip_Word{
  color: black;
  font-style: italic;
  font-weight: 600;
  text-decoration: underline;
}

#koffer, #zweeds{
  max-width: 90%;
  margin: auto;
  max-height: 400px; 
}

span.quote{
  font-style: italic;
  font-weight: 600;
}
span.quote::before{
  content: open-quote;
  font-size: 1.2rem;
  color: rgb(144, 144, 144);
  line-height: 0;
}
span.quote::after{
  content: close-quote;
  font-size: 1.2rem;
  color: rgb(144, 144, 144);
  line-height: 0;
}

@media screen and (min-width: 800px) {
  .prevNextButs{
    max-width: 70ch;
  }
  #prevBlog, #nextBlog {
    max-width: 20ch;
  }
}